


















































































import {
  Component, PropSync, Vue,
} from 'vue-property-decorator';
import AccessMoreStrategiesViewModel from '@/vue-app/view-models/allianz-dashboard/access-more-strategies/access-more-strategies-view-model';

@Component({
  name: 'AccessMoreStrategiesDialog',
  components: {
    FlagshipProfilingStepEconomicDependents: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepEconomicDependents.vue'),
    FlagshipProfilingStepEmploymentSituation: () => import('@/vue-app/components/flagship/flagship-profiling/FlagshipProfilingStepEmploymentSituation.vue'),
    AccessMoreStrategiesFinancialHabits: () => import('@/vue-app/components/allianz-dashboard/access-more-strategies/AccessMoreStrategiesFinancialHabits.vue'),
    DefineInvestmentTerm: () => import('@/vue-app/components/allianz-dashboard/access-more-strategies/DefineInvestmentTerm.vue'),
    AccessMoreStrategiesRiskTolerance: () => import('@/vue-app/components/allianz-dashboard/access-more-strategies/AccessMoreStrategiesRiskTolerance.vue'),
    InvestmentStrategyResult: () => import('@/vue-app/components//allianz-dashboard/access-more-strategies/InvestmentStrategyResult.vue'),
    InvestmentStrategyInitialBalance: () => import('@/vue-app/components/allianz-dashboard/access-more-strategies/InvestmentStrategyInitialBalance.vue'),
    InvestmentStrategyConfigureContribution: () => import('@/vue-app/components/allianz-dashboard/access-more-strategies/InvestmentStrategyConfigureContribution.vue'),
    InvestmentStrategySchedulePayment: () => import('@/vue-app/components/allianz-dashboard/access-more-strategies/InvestmentStrategySchedulePayment.vue'),
    Loader: () => import('@/vue-app/components/custom/Loader.vue'),
  },
})
export default class AccessMoreStrategiesDialog extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  access_more_strategies_view_model = Vue.observable(
    new AccessMoreStrategiesViewModel(this),
  );

  async endProcess() {
    const finished = await this.access_more_strategies_view_model.endProcess();
    if (finished) {
      this.synced_is_open = false;
    }
  }

  close() {
    this.synced_is_open = false;
  }

  created() {
    this.access_more_strategies_view_model.initialize();
  }

  prevStep() {
    if (this.access_more_strategies_view_model.current_step === 0) {
      this.close();
    } else {
      this.access_more_strategies_view_model.prevStep();
    }
  }
}

